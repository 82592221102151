<template>
  <div class="container-fluid bg-light p-3 ht-full">
    <div class="row">
      <div class="text-center">
        <img alt="MainPlan logo" src="../assets/mainplan_logo.png" class="mx-auto" />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-sm-12 col-md-4 offset-md-4"> -->
        <div class="form-wrapper mx-auto">
          <div class="card bg-white  mt-4">
            <div class="text-center sitename" >
              <img alt="Site Name" src="../assets/sitename.png" class="mx-auto" />
            </div>
            <article class="card-body" style="margin:0px !important;">
              
              <div class="wizard-slide" v-if="currentSlide==1">
                <Asset v-bind:assets = "assets" :selectedLevels="selectedLevels" @AssetSelectionChanged = "OnAssetSelection"></Asset>
              </div>
              <div class="wizard-slide" v-if="currentSlide==2">
                <div class="alert text-center alert-success" role="alert" >
                  <div class="close-button" @click="CancelData">
                    <i class="fas fa-window-close text-danger"></i>
                  </div>
                  <div class="edit-button" @click="ChangeData">
                    <i class="fas fa-edit text-primary"></i>
                  </div>
                  <div class="mt-2">{{message}}</div>
                </div>
                <form>
                  <div class="form-group mt-3">
                    <label for="faults" class="font-weight-bold">Fault or Problem (required)</label>
                    <select id="faults" class="form-control form-control-lg" v-model="selectedFault" >
                      <option selected disabled value="">Choose...</option>
                      <option v-for="item in faults" :key="item[1]" v-bind:value="item" >{{item[0]}}</option>
                    </select>                
                  </div>
                  <div class="form-group mt-3">
                    <label for="fault_detail">Further fault/problem details</label>
                    <textarea v-model="selectedFaultDetails" class="form-control form-control-lg" id="fault_detail" row="2"></textarea>
                  </div>
                  <div class="form-group mt-3">
                    <label for="urgency" class="font-weight-bold">Urgency (required)</label>
                    <select id="urgency" class="form-control form-control-lg" v-model="selectedUrgency" >
                      <option selected disabled value="">Choose...</option>
                      <option v-for="item in urgencyList" :key="item[1]" v-bind:value="item">{{item[0]}}</option>
                    </select>                
                  </div>
                  
                  <div class="form-group mt-3">
                    <label for="urgency" class="font-weight-bold">Job Raised by (required)</label>
                    <select id="urgency" class="form-control form-control-lg" v-model="raisedBy" >
                      <option selected disabled value="">Choose...</option>
                      <option v-for="item in employeesList" :key="item[1]" v-bind:value="item">{{item[0]}}</option>
                    </select>                
                  </div>

                </form>
              </div>
              <div class="form-group mt-4">
                  <button :disabled="formDisabled" type="submit" class="btn btn-success btn-block" @click.prevent="OnClickNextSlide" style="width:100%">{{buttonText}}</button>
              </div>  
            </article>
          </div>
          <div class="text-center text-success mt-1" v-if="submitStatus">
            <h5>Job Submitted Successfully</h5>
          </div>
        <!-- </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Asset from "@/components/Asset.vue";
import axios from "axios";
import appConfig from "@/config/config.js";


export default {
  name: "Home",
  data(){
    return{
      headings : ["Asset Selection", "Details"],
      currentSlide : 1,
      totalSlides : 2,
      selectedAsset : null,
      selectedLevels : ["","","",""],
      selectedFault : "",
      selectedFaultDetails : "",
      selectedUrgency : "",
      raisedBy : "",
      assets:[],
      faults:[],
      urgencyList : [],
      employeesList : [],
      submitting: false,
      submitStatus : false
    }
  },
  computed:{
    message : function(){
      return this.selectedAsset.pname;
    },
    formDisabled: function(){

      if(this.submitting===true)
        return true;

      if(this.currentSlide == 1){
        return this.selectedAsset === null ? true : !this.selectedAsset.valid;
      }
      else{
        return !(this.selectedFault !== "" && this.selectedUrgency !== "" && this.raisedBy !== "");
      }
    },
    buttonText: function(){
      return this.currentSlide == 1 ? "Next" : "Submit";
    }
  },
  components: {
    Asset,
  },
  methods:{

    OnClickNextSlide : function(){

        if(this.currentSlide < this.totalSlides){
          this.currentSlide ++;
        }
        else{
          
          let currentDate = new Date();
          this.submitting = true;
          var data = new FormData();
          data.append('row_id', this.selectedAsset.row_id);
          data.append('pkey', this.selectedAsset.pkey);
          data.append('fault_name', this. selectedFault[0]);
          data.append('fault_code', this.selectedFault[1]);
          data.append('fault_detail', this.selectedFaultDetails);
          data.append('urgency', this.selectedUrgency[0]);
          data.append('urgency_code', this.selectedUrgency[1]);
          data.append('raised_by', this.raisedBy[0]);
          data.append('hour', currentDate.getHours());
          data.append('minute', currentDate.getMinutes());
          data.append('second', currentDate.getSeconds());
          data.append('month', currentDate.getMonth());
          data.append('day', currentDate.getDate());
          data.append('year', currentDate.getFullYear());

          data.append('skey', 'lonlasdjkkj324209dsl34098348');
          axios.post(appConfig.serverUrl+"server/submit.php", data)
          .then((response) => {
            //console.log(response.data);
            this.submitting = false;
            if(response.data.success === true){
              this.CancelData();
              this.submitStatus = true;
              setTimeout(() => this.submitStatus = false, 5000);
            }
          });

          

        }

    },
    OnAssetSelection : function(obj){
      this.selectedAsset = obj.asset;
      this.selectedLevels = obj.selectedLevels;
      console.log(this.selectedLevels);
    },
    CancelData : function(){

      this.selectedLevels.forEach(function(part, index, theArray) {
        theArray[index] = "";
      });
      this.selectedAsset = null;
      this.currentSlide--;
      this.selectedFault = "";
      this.selectedFaultDetails = "";
      this.selectedUrgency = "";
      this.raisedBy = "";
      
    },
    ChangeData : function(){
      this.currentSlide--;
    }
  },
  mounted(){

    //fetch assets
    axios.get(appConfig.serverUrl+"server/assets.php")
    .then((response) => {
      if(response.data && response.data.success){
        this.assets = response.data.data;
        //console.log(this.assets);
      }
    })
    .catch((err)=>{
      console.log(err);
    });


    //fetch faults
    axios.get(appConfig.serverUrl+"server/faults.php")
    .then((response) => {
      if(response.data && response.data.success){
        this.faults = response.data.data;
         //console.log(this.faults);
      }
    })
    .catch((err)=>{
      console.log(err);
    });

    //fetch urgency
    axios.get(appConfig.serverUrl+"server/urgency.php")
    .then((response) => {
      if(response.data && response.data.success){
        this.urgencyList = response.data.data;
        console.log(this.urgencyList);
      }
    })
    .catch((err)=>{
      console.log(err);
    });


    //fetch employees list
    axios.get(appConfig.serverUrl+"server/employees.php")
    .then((response) => {
      if(response.data && response.data.success){
        this.employeesList = response.data.data;
        //console.log(this.employeesList);
      }
    })
    .catch((err)=>{
      console.log(err);
    });



  }
};
</script>

<style scoped>
label{
  font-weight: bold;
  color:darkslategray;
}
.close-button{
  position: absolute;
  top: 0;
  right: 8px;
  font-size: 20px;
  cursor: pointer;
}
.edit-button{
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 20px;
  cursor: pointer;
}
.form-wrapper{
  width:100%;
  max-width: 500px;
}
.sitename{
    width: 100%;
}
</style>
